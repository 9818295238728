import React from 'react'
import { Ping, Waveform, Pulsar, Ring, Ripples, ThreeBody, Orbit, ChaoticOrbit, SuperBalls, Wobble, Metronome, DotWave, DotPulse, LeapFrog, NewtonsCradle, Momentum, Jelly, JellyTriangle, DotSpinner, RaceBy, LineWobble, Handsprings, Pinwheel, MrMiyagi } from '@uiball/loaders'

interface Props {
    size?: number;
    speed?: number;
    color?: string;
    className?: string;
    variant?: "Ring" | "Waveform" | "Pulsar" | "Ping" | "Ripples" | "ThreeBody" | "Orbit" | "ChaoticOrbit" | "SuperBalls" | "Wobble" | "Metronome" | "DotWave" | "DotPulse" | "LeapFrog" | "NewtonsCradle" | "Momentum" | "Jelly" | "JellyTriangle" | "DotSpinner" | "RaceBy" | "LineWobble" | "Handsprings" | "Pinwheel" | "MrMiyagi"
    lineWeight?: number;
}

export const component: { [key: string]: React.FC<Props> } = {
    Ping,
    Waveform,
    Pulsar,
    Ring,
    Ripples,
    ThreeBody,
    Orbit,
    ChaoticOrbit,
    SuperBalls,
    Wobble,
    Metronome,
    DotWave,
    DotPulse,
    LeapFrog,
    NewtonsCradle,
    Momentum,
    Jelly,
    JellyTriangle,
    DotSpinner,
    RaceBy,
    LineWobble,
    Handsprings,
    Pinwheel,
    MrMiyagi,
}

export const Loader = ({
    size = 45,
    speed = 2,
    color = 'black',
    className,
    variant = 'Ping',
    lineWeight
}: Props) => {
    const Component = component[variant]
    return (
        <div className={className}>
            <Component
                size={size}
                speed={speed}
                color={color}
                lineWeight={lineWeight}
            />
        </div>
    )
}