import {
  Contact,
  IconsProps,
  Instagram,
  Twitter,
  YouTube,
  TikTok,
  X,
  Profile,
  LinkIcon,
  Board,
  Palette,
  Upload,
  Share,
  Post,
  Settings,
  Help,
  Doc,
  Logout,
  EyeOutline,
  Chart,
  PencilCircleOutline,
  Search,
  Heart,
  Home,
  Plus,
  Minus,
  Trash,
  Privacy,
} from './';

export const Icon: { [key: string]: React.FC<IconsProps> } = {
  Instagram,
  Twitter,
  YouTube,
  TikTok,
  X,
  Profile,
  LinkIcon,
  Board,
  Palette,
  Upload,
  Share,
  Post,
  Settings,
  Help,
  Doc,
  Contact,
  Logout,
  EyeOutline,
  Chart,
  PencilCircleOutline,
  Search,
  Heart,
  Home,
  Plus,
  Minus,
  Trash,
  Privacy,
};
