import React, { useEffect, useState } from 'react';
import { BasicFab } from '../../components/atoms/button';
import { useNavigate } from 'react-router-dom';
import { Liveclips } from '../../../types';
import { getAllDocument } from '../../../api/firestore';
import { VideoCard } from '../../components/atoms/card/VideoCard';
import { formatDateHyphen } from '../../../utils/helper';
import { Timestamp } from 'firebase/firestore';
import { useAuthContext } from '../../../provider/AuthProvider';
import LoginModal from '../../components/molecules/modal/LoginModal';
import { useModal } from '../../../hooks/useModal';

export const LiveClips = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Liveclips[]>([]);
  const { authUser } = useAuthContext();
  const loginModal = useModal();
  useEffect(() => {
    getAllDocument<Liveclips>('liveclips').then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="relative">
      <BasicFab
        className="fixed bottom-10 right-5"
        color="primary"
        icon="Post"
        onClick={() => {
          if(!authUser){
            loginModal.openModal();
            return;
          }
          navigate('/liveclips/post');
        }}
      />
      {data.map((item, index) => {
        return (
          <VideoCard
            ogp={item.ogp}
            date={item.date}
            upload_count={item.upload_count}
            onClick={() => {
              navigate(
                '/liveclips/contents/' +
                  formatDateHyphen((item.date as Timestamp).toDate())
              );
            }}
            key={index}
          />
        );
      })}
        <div className='p-3'/>
      <LoginModal
        open={loginModal.isOpen}
        handleClose={() => {
          loginModal.closeModal();
        }}
      />
    </div>
  );
};
