import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '../../../../assets/icons/Component';
import { LiveclipsDetails } from '../../../../types';
import { shortenString } from '../../../../utils/helper';

interface Props {
  details: LiveclipsDetails;
  onClick: () => void;
}

export const VideoContentCard = ({ details, onClick }: Props) => {
  const TikTok = Icon['TikTok'];
  const YouTube = Icon['YouTube'];
  const Instagram = Icon['Instagram'];
  return (
    <Wrapper onClick={onClick}>
      <img src={details.thumbnail} alt="ogp" className="w-full h-[178px]" />
      <div className="absolute bottom-0.5 left-4 text-primary text-[17px] noto-bold">
        {shortenString(details.description || '説明なし', 10)}
      </div>
      <div className="absolute bottom-0.5 right-16 text-success text-[17px] noto-bold">
        {details.url.length > 1 ? `part1~${details.url.length}` : '単一'}
      </div>
      <div className="absolute bottom-0.5 right-4">
        {details.platform === 'tiktok' && <TikTok fill="#000" />}
        {details.platform === 'youtube' && <YouTube fill="#000" />}
        {details.platform === 'instagram' && <Instagram fill="#000" />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 338px;
  height: 212px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  margin: 15px auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;
