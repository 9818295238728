import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { H2, Label } from '../../components/atoms/header';
import { CustomButton } from '../../components/atoms/button';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import AlertDialog from '../../components/molecules/modal/AlertDialog';
import {
  getAllDocument,
  setSpecificCollection,
  setSubSpecificCollection,
} from '../../../api/firestore';
import { convertToCategoryCountFormat, randomID } from '../../../utils/helper';
import { Features } from '../../../types';
import { getOGP } from '../../../api/callable';
import { useAuthContext } from '../../../provider/AuthProvider';

export const FeaturePost = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const { isOpen, openModal, closeModal } = useModal();
  const [data, setData] = useState<Features>({} as Features);
  const { authUser } = useAuthContext();
  const [category, setCategory] = useState<string[]>([]);
  const isValid = (data: FieldValues) => {
    openModal();
    setData({
      ...data,
      date: new Date(),
      category: data.category === 'その他' ? data.categoryOther : data.category,
    } as Features);
  };

  const isInValid = (errors: unknown) => {
    console.log(errors);
    console.log('Fail Login');
  };

  useEffect(() => {
    getAllDocument<Features>('features').then((res) => {
      setCategory(convertToCategoryCountFormat(res.data));
    });
  }, []);

  return (
    <form
      onSubmit={handleSubmit(isValid, isInValid)}
      className="flex w-full flex-col gap-2"
    >
      <H2 className="text-danger text-center dela">新規投稿</H2>
      <Label>カテゴリ</Label>
      <FormControl>
        <Select
          type="select"
          required
          color="primary"
          {...register('category', { required: '選択してください' })}
          className="w-[340px] m-auto"
          placeholder="選択してください"
        >
          {[...category, 'その他'].map((value) => {
            return (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
        {watch('category') === 'その他' && (
          <TextField
            {...register('categoryOther', {
              required: 'カテゴリを入力してください',
            })}
            placeholder="カテゴリを入力してください"
            className="w-[340px] m-auto mt-2"
            type="title"
            name="categoryOther"
            variant="outlined"
            required
          />
        )}
      </FormControl>
      <Label>記事タイトル</Label>
      <TextField
        {...register('title', { required: 'タイトルを入力してください' })}
        placeholder="タイトルを入力してください"
        className="w-[340px] m-auto"
        type="title"
        name="title"
        variant="outlined"
        required
      />
      <Label>記事URL</Label>
      <TextField
        {...register('url', { required: 'URLを入力してください' })}
        placeholder="URLを入力してください"
        className="w-[340px] m-auto"
        type="url"
        name="url"
        variant="outlined"
        required
      />
      <CustomButton className="mt-10 w-[336px] m-auto" type="submit">
        投稿する
      </CustomButton>
      <CustomButton
        className="my-5 w-[336px] m-auto"
        color="primary"
        variant='text'
        onClick={() => {
          navigate(-1);
        }}
      >
        戻る
      </CustomButton>
      <AlertDialog
        open={isOpen}
        handleClose={closeModal}
        onClick={async () => {
          const ogp = (await getOGP(data.url)) as string;
          const id = randomID();
          await setSpecificCollection<Features>('features', id, {
            ...data,
            id: id,
            uploader: {
              uid: authUser?.uid ?? '',
              screenName: authUser?.reloadUserInfo.screenName ?? '',
              photoURL: authUser?.photoURL ?? '',
              displayName: authUser?.displayName ?? '',
            },
            thumbnail: ogp,
          });
          await setSubSpecificCollection<Features>(
            'users',
             authUser?.reloadUserInfo.screenName,
            'features',
            id,
            {
              ...data,
              id: id,
              uploader: {
                uid: authUser?.uid ?? '',
                screenName: authUser?.reloadUserInfo.screenName ?? '',
                photoURL: authUser?.photoURL ?? '',
                displayName: authUser?.displayName ?? '',
              },
              thumbnail: ogp,
            }
          );
          navigate('/feature');
        }}
        title={'以下の内容で投稿しますか？'}
      >
        <div className="flex flex-col gap-2">
          <Label sm>カテゴリ</Label>
          <div className="ml-7 text-gray70">{data.category}</div>
          <Label sm>記事タイトル</Label>
          <div className="ml-7 text-gray70">{data.title}</div>
          <Label sm>記事URL</Label>
          <div className="ml-7 text-gray70 overflow-scroll">{data.url}</div>
        </div>
      </AlertDialog>
    </form>
  );
};
