import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getSubCollectionCount } from './count';

export const deleteDocument = async (collectionId: string, docId: string) => {
  await deleteDoc(doc(db, collectionId, docId));
};

export const deleteSubDocument = async (
  collectionId: string,
  docId: string,
  subCollectionId: string,
  subDocId: string
) => {
  await deleteDoc(doc(db, collectionId, docId, subCollectionId, subDocId));
};

export const deleteFeatures = async (id: string, user: string) => {
  await deleteDoc(doc(db, 'features', id));
  await deleteDoc(doc(db, 'users', user, 'features', id));
};
export const deleteLiveClips = async (
  date: string,
  id: string,
  user: string
) => {
  const upload_count = await getSubCollectionCount('liveclips', date, 'videos');
  if (upload_count === 1) {
    await deleteDoc(doc(db, 'liveclips', date, 'videos', id));
    await deleteDoc(doc(db, 'liveclips', date));
  } else {
    await deleteDoc(doc(db, 'liveclips', date, 'videos', id));
    await updateDoc(doc(db, 'liveclips', date), {
      upload_count: upload_count - 1,
    });
  }
  await deleteDoc(doc(db, 'users', user, 'liveclips', id));
};
