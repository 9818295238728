import { collection, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase';

export const getSubCollectionCount = async (
  collectionId: string,
  docId: string,
  subCollectionId: string
) => {
  const querySnapshot = await collection(
    db,
    collectionId,
    docId,
    subCollectionId
  );
  const snapshot = await getCountFromServer(querySnapshot);
  console.log(snapshot.data().count);
  return snapshot.data().count;
};
