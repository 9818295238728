import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { formatDate } from '../../../../utils/helper';
import { Icon } from '../../../../assets/icons/Component';
import { Liveclips } from '../../../../types';
import { Timestamp } from 'firebase/firestore';

interface Props extends Partial<Liveclips> {
  children?: ReactNode;
  onClick?: () => void;
}

export const VideoCard = (props: Props) => {
  const Upload = Icon['Upload'];
  return (
    <Wrapper onClick={props.onClick}>
      <img src={props.ogp} alt="ogp" className="w-full" />
      <div className="absolute bottom-0.5 left-4 text-primary text-[17px] noto-bold">
        {formatDate((props.date as Timestamp).toDate())}
      </div>
      <div className="absolute bottom-0.5 right-10">
        <Upload fill="#707070" />
      </div>
      <div className="absolute bottom-0.5 right-0.5 text-left w-[40px] text-gray70 noto-bold">
        {props.upload_count}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 338px;
  height: 212px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  margin: 15px auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;
