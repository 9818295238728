import * as React from 'react';
import { User } from 'firebase/auth';
import { createContext, useContext, useState } from 'react';
interface AuthContextProps {
  authUser: User | null;
  isLogin: boolean;
  setAuthUser: React.Dispatch<React.SetStateAction<User | null>>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}
const AuthContext = createContext<AuthContextProps>({
  authUser: null,
  isLogin: false,
  setAuthUser: () => {},
  setIsLogin: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const useAuthContext = () => {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: Props) => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [isLogin, setIsLogin] = useState<boolean>(false);

  const value:AuthContextProps = {
    authUser,
    isLogin,
    setAuthUser,
    setIsLogin,
  };
// const updateAuthUser = (user: User | null) => {
//   setAuthUser(user);
//   setIsLogin(true);
// }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
