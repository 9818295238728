import { DocumentData, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const setSpecificCollection = async <T extends DocumentData>(
  collectionId: string,
  docId: string,
  data: T
) => {
  await setDoc(doc(db, collectionId, docId), data);
};

export const setSubSpecificCollection = async <T extends DocumentData>(
  collectionId: string,
  docId: string,
  subCollectionId: string,
  subDocId: string,
  data: T
) => {
  await setDoc(doc(db, collectionId, docId, subCollectionId, subDocId), data);
};
