import dayjs from 'dayjs';
import { Features } from '../types';

export const randomID = () => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const N = 20;
  return Array.from(crypto.getRandomValues(new Uint8Array(N)))
    .map((n) => S[n % S.length])
    .join('');
};

export const shortenString = (str: string, length: number) => {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  } else {
    return str;
  }
};

export const formatDate = (date: Date) => {
  const y = date.getFullYear();
  const m = ('00' + (date.getMonth() + 1)).slice(-2);
  const d = ('00' + date.getDate()).slice(-2);
  return y + '/' + m + '/' + d;
};
export const formatDateHyphen = (date: Date) => {
  const y = date.getFullYear();
  const m = ('00' + (date.getMonth() + 1)).slice(-2);
  const d = ('00' + date.getDate()).slice(-2);
  return y + '-' + m + '-' + d;
};

export const dayjsToString = (dayjs: dayjs.Dayjs) => {
  return dayjs.format('YYYY-MM-DD');
};
export const dayjsToEmbeddedText = (dayjs: dayjs.Dayjs) => {
  return dayjs.format('YYYY/MM/DD');
};

export const dayjsToDate = (dayjs: dayjs.Dayjs) => {
  return new Date(dayjs.format('YYYY-MM-DD'));
};

export const getPlatformName = (url: string) => {
  if (url.includes('youtube') || url.includes('youtu.be')) {
    return 'youtube';
  } else if (url.includes('instagram')) {
    return 'instagram';
  } else if (url.includes('tiktok')) {
    return 'tiktok';
  } else {
    return 'other';
  }
};

export const isMobile = () => {
  const ua = navigator.userAgent;
  if (
    ua.indexOf('iPhone') > 0 ||
    (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
  ) {
    return true;
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return false;
  } else {
    return false;
  }
};

export const tweetShareMessage = (text: string, url: string) => {
  return `http://twitter.com/intent/tweet?url=${encodeURIComponent(
    url
  )}&text=${text}&hashtags=惟真伝心板`;
};

export const createOgpUrl = (path: string, date: string, id: string) => {
  return `${process.env.REACT_APP_OGP_URL}ogp${path}?id=${id}&date=${date}`;
};

export const convertToCategoryCountFormat = (dataArray: Array<Features>) => {
  const uniqueSet = new Set(dataArray.map((item) => item.category));
  return Array.from(uniqueSet)
    .map((category) => {
      return category;
    })
    .sort((a, b) => b.localeCompare(a));
};
