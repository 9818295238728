import React, { useEffect, useState } from 'react';
import { VideoContentCard } from '../../components/atoms/card/VideoContentCard';
import { BasicFab } from '../../components/atoms/button';
import { useNavigate, useParams } from 'react-router-dom';
import { LiveclipsDetails } from '../../../types';
import { getSubAllCollection } from '../../../api/firestore';
import { formatDate } from '../../../utils/helper';
import { Timestamp } from 'firebase/firestore';

export const LiveClipsContents = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<LiveclipsDetails[]>([]);
  const params = useParams();

  useEffect(() => {
    getSubAllCollection<LiveclipsDetails>(
      'liveclips',
      params.date as string,
      'videos',
      'postDate'
    ).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <div className="relative">
      <div className="text-center px-2 py-3 border-solid border w-[60%] m-auto mt-3 bg-black text-white rounded-[8px] text-[100%] noto-bold">
        {data[0] &&
          formatDate((data[0].date as Timestamp).toDate()) + 'のインライ'}
      </div>
      <BasicFab
        className="fixed bottom-10 right-5"
        color="primary"
        icon="Post"
        onClick={() => {
          navigate('/liveclips/post');
        }}
      />
      {data.map((item, index) => {
        return (
          <VideoContentCard
            details={item}
            onClick={() => {
              navigate('/liveclips/contents/' + params.date + '/' + item.id);
            }}
            key={index}
          />
        );
      })}
        <div className='p-3'/>
    </div>
  );
};
