import React, { useState } from 'react';
import { H1, H6 } from '../components/atoms/header';
import { PanelButton } from '../components/atoms/button/PanelButton';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../components/atoms/loaders';
import { Footer } from '../components/atoms/footer';
export const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <H1 className="dela text-danger text-center no_copy">惟真伝心板</H1>
      <H6 className="w-[90%] m-auto text-gray70">
        当サイトは非公式および完全個人が運営している小田惟真くんの非公式Wikiです。不適切な投稿はお断りしています。そのような投稿をお見かけした場合は、運営者の判断で削除いたします。
      </H6>
      <div className="flex flex-col gap-2 mt-3">
        <div className="flex justify-center items-center gap-3">
          <PanelButton
            icon="Profile"
            onClick={() => {
              navigate('profile');
            }}
          >
            Profile
          </PanelButton>
          <PanelButton
            icon="LinkIcon"
            onClick={() => {
              navigate('feature');
            }}
          >
            特集
          </PanelButton>
          <PanelButton
            icon="Instagram"
            onClick={() => {
              navigate('liveclips');
            }}
          >
            切り抜き
          </PanelButton>
        </div>
        <div className="flex justify-center items-center gap-3">
          <PanelButton icon="TikTok" disabled>推しカメラ</PanelButton>
          <PanelButton icon="Palette" disabled>
            Fan Art
          </PanelButton>
          <PanelButton icon="Board" disabled>
            掲示板
          </PanelButton>
        </div>
      </div>
      {!isLoading && (
        <Loader
          variant="Pulsar"
          speed={1.2}
          size={100}
          color="#D83131"
          className="flex mx-auto items-center justify-center mt-16"
        />
      )}
      <div className="w-[90%] m-auto my-3">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="ishin_supafuru"
          onLoad={() => {
            setIsLoading(true);
          }}
        />
      </div>
      <Footer />
    </div>
  );
};
