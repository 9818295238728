import { Fab } from '@mui/material';
import React from 'react';
import { Icon } from '../../../../assets/icons/Component';
import { Icons, Size } from '../../../../types/union.type';
import { IconLabelSize } from '../../../../config/size';
interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: Icons;
  size?: Size;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  onClick?: () => void;
}
export const BasicFab = (props: Props) => {
  const Component = Icon[props.icon ?? 'Contact'];
  return (
    <Fab
      color={props.color}
      onClick={props.onClick}
      aria-label="add"
      className={props.className}
      style={{
        width: IconLabelSize[props.size ?? 'xlarge']['size'],
        height: IconLabelSize[props.size ?? 'xlarge']['size'],
      }}
    >
      <Component
        fill="#fff"
        size={IconLabelSize[props.size ?? 'xlarge']['fontSize']}
      />
    </Fab>
  );
};
