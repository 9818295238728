import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { Loader } from '../../atoms/loaders';

interface Props {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  onClick: () => void;
  color?: 'primary' | 'secondary' | 'error';
  title: string;
}
export default function AlertDialog(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        color={props.color}
      >
        <DialogTitle id="alert-dialog-title" color={props.color}>
          {props.title}
        </DialogTitle>
        <div className="mx-3">{props.children}</div>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color={props.color}
            disabled={isLoading}
          >
            いいえ
          </Button>
          {isLoading ? (
            <Loader
              variant="Jelly"
              speed={1}
              size={40}
              color="#0078E7"
              className="flex w-[50px]"
            />
          ) : (
            <Button
              onClick={async () => {
                setIsLoading(true);
                await props.onClick();
                setIsLoading(false);
                props.handleClose();
              }}
              autoFocus
              variant="contained"
              color={props.color}
            >
              はい
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
