import { getFunctions, httpsCallable } from "firebase/functions";
import { createOGPTypes } from "../types";

export const getOGP = async (
    url: string,
) => {
    const functions = getFunctions();
    const addFunction = httpsCallable(functions, "getOGP");
    const res = await addFunction({
        url: url,
    });
    return res.data;
};

export const createOGP = async (
    path: string,
    date: string,
    url: string,
) => {
    const functions = getFunctions();
    const addFunction = httpsCallable(functions, "createOGP");
    const res = await addFunction({
        path: path,
        date: date,
        url: url,
    });
    return res.data as createOGPTypes;
};