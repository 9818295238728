import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Icons } from "../../../../types/union.type";
import { Icon } from "../../../../assets/icons/Component";

interface navigationItemPathTypes {
    name: string;
    path: string;
    icons: Icons;
}

const navigationItem: navigationItemPathTypes[] = [
    {
        name: "Home",
        path: "/",
        icons: "Home",
    },
    {
        name: "Profile",
        path: "/profile",
        icons: "Profile",
    },
    {
        name: "特集",
        path: "/feature",
        icons: "LinkIcon",
    },
    {
        name: "切り抜き",
        path: "/liveclips",
        icons: "Instagram",
    },
    {
        name: "利用規約",
        path: "/terms",
        icons: "Chart",
    },
    {
        name: "プライバシーポリシー",
        path: "/policy",
        icons: "Privacy",
    },
    {
        name: "お問い合わせ",
        path: "https://docs.google.com/forms/d/e/1FAIpQLScEFbHLF2uz-h7EGRk-72tdZQJ0pHQi5Cbf2Aq_iQ2Vra07gQ/viewform?usp=sf_link",
        icons: "Contact",
    },
];
interface DrawerLeftProps {
    isMenuOpen: boolean;
    setIsmenu: (isMenuOpen: boolean) => void;
}
export const DrawerLeft = ({ isMenuOpen, setIsmenu }: DrawerLeftProps) => {

    const generateIcon = (icon: Icons) => {
        const Component = Icon[icon];
        return <Component fill="#707070" />;
    }
    return (
        <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => {
                setIsmenu(false);
            }}
        >
            <List>
                {navigationItem.map((path, index) => (
                    <div key={index}>
                        {path.name === "利用規約" && <Divider />}
                        <ListItem disablePadding>
                            <Link
                                className="w-full"
                                to={path.name === "お問い合わせ" ? "" : path.path}
                                onClick={() => {
                                    setIsmenu(false);
                                    if (path.name === "お問い合わせ")
                                        window.location.href = path.path;
                                }}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        {generateIcon(path.icons)}
                                    </ListItemIcon>
                                    <ListItemText primary={path.name} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </div>
                ))}
            </List>
        </Drawer>
    );
};
