import React from 'react';
import { Footer } from '../components/atoms/footer';

export const Terms = () => {
  return (
    <div className="japanese_L mx-2 mt-2">
      <div className="mx-auto max-w-3xl rounded-lg bg-white p-[2%] [&_h2]:my-[0.83em] [&_h2]:text-[1.5em] [&_h2]:font-bold [&_li]:list-decimal [&_ul]:ml-4 [&_ul_ul>li]:list-[lower-latin]">
        <h1 className="japanese_L my-5 text-center text-[2em] font-bold text-runticketGrayText">
          利用規約
        </h1>
        <div className="m-auto my-5 w-[90%]">
          <h2>1. はじめに</h2>
          <p>
            惟真伝心板（以下「当サイト」とします）は、小田惟真くんの非公式ファンwikiサイトとして運営されています。当サイトを利用する前に、以下の利用規約をよくお読みください。
          </p>

          <h2>2. 著作権について</h2>
          <p>
            当サイトで共有されるURLや情報は、それぞれの著作権者が保有しています。利用者は著作権法および関連する法律・条約を遵守するものとします。
          </p>

          <h2>3. 掲示板の利用</h2>
          <ol>
            <li>
              掲示板に投稿する際は、他者の著作権を尊重してください。無断でのコンテンツの転載、並びにFCに投稿されている内容の共有は禁止します。
            </li>
            <li>
              映像や画像の直接的なアップロードはできません。URL形式での共有のみを許可します。
            </li>
            <li>
              不適切な内容、誹謗中傷、違法な内容の投稿は禁止します。発見した場合、削除または修正を行うことがあります。
            </li>
          </ol>

          <h2>4. 免責事項</h2>
          <ol>
            <li>
              当サイト上で共有されるURLの内容について、その真実性や安全性を保証するものではありません。リンク先でのトラブルについて、当サイトは一切の責任を負いません。
            </li>
            <li>
              当サイトは、サービスの中断やデータの損失などに対して、一切の責任を負いません。
            </li>
          </ol>

          <h2>5. プライバシー</h2>
          <p>
            当サイトは、利用者のプライバシーを尊重します。詳しくは、
            <a href="/policy" className="text-primary underline">
              プライバシーポリシー
            </a>
            をご参照ください。
          </p>

          <h2>6. 規約の変更</h2>
          <p>
            当サイトは、利用規約を予告なく変更することがあります。変更後の利用規約は、当サイト上での公開をもって、効力を発生するものとします。
          </p>

          <h2>7. その他</h2>
          <p>
            利用規約に明記されていない点や疑問に関しては、お問い合わせフォームより当サイト運営者までお問い合わせください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
