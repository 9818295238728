import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { theme } from '../../../styles/theme';
import { Icons, Size } from '../../../../types/union.type';
import { IconLabelSize } from '../../../../config/size';
import { Icon } from '../../../../assets/icons/Component';

interface Props {
  children?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  size?: Size;
  padding?: string;
  icon?: Icons;
  href?: string;
  className?: string;
  onClick?: () => void;
}

export const OptionalButton: React.FC<Props> = ({
  children,
  variant = 'contained',
  color = 'primary',
  padding = '8px 16px',
  size = 'small',
  icon = 'Contact',
  href,
  className,
  onClick,
}) => {
  const Component = Icon[icon];
  return (
    <ThemeProvider theme={theme}>
      <Button
        style={{
          borderRadius: '8px',
          padding: padding,
          textTransform: 'none',
        }}
        className={className}
        variant={variant}
        color={color}
        href={href}
        onClick={onClick}
        startIcon={
          <Component
            fill={
              variant === 'contained' ? '#fff' : theme.palette[color]['main']
            }
            size={IconLabelSize[size]['size']}
          />
        }
      >
        <div
          style={{
            fontSize: IconLabelSize[size]['fontSize'],
            color:
              variant === 'contained' ? '#fff' : theme.palette[color]['main'],
          }}
        >
          {children}
        </div>
      </Button>
    </ThemeProvider>
  );
};
