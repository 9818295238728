import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { H2, Label } from '../../components/atoms/header';
import { CustomButton, OptionalButton } from '../../components/atoms/button';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import AlertDialog from '../../components/molecules/modal/AlertDialog';
import { Liveclips, LiveclipsDetails } from '../../../types';
import { createOGP } from '../../../api/callable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja';
import { Minus } from '../../../assets/icons';
import {
  dayjsToDate,
  dayjsToEmbeddedText,
  dayjsToString,
  getPlatformName,
  randomID,
} from '../../../utils/helper';
import dayjs, { Dayjs } from 'dayjs';
import {
  getAllDocument,
  getSubCollectionCount,
  setSpecificCollection,
  setSubSpecificCollection,
} from '../../../api/firestore';
import { useAuthContext } from '../../../provider/AuthProvider';

export const LiveClipsPost = () => {
  const [elementCount, setElementCount] = useState<string[]>(
    new Array(1).fill('')
  );
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const { isOpen, openModal, closeModal } = useModal();
  const [date, setDate] = useState<Dayjs | null>(null);
  const [url, setUrl] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [select, setSelect] = useState<string[]>(['その他']);
  const { authUser } = useAuthContext();

  const isValid = (data: FieldValues) => {
    openModal();
    const result = Object.keys(data)
      .filter((key) => key.includes('Part') && data[key] !== '')
      .map((key) => data[key]);
    console.log(result);
    setUrl(result);
    setDescription(data.description);
    console.log('success');
  };

  const isInValid = (errors: unknown) => {
    console.error(errors);
  };

  useEffect(() => {
    if (!authUser) window.location.href = '/';
    getAllDocument<Liveclips>('liveclips').then((res) => {
      setSelect((prev) => [...prev, ...res.id]);
    });
  }, []);

  useEffect(() => {
    const selected = watch('dateSelect');
    if (selected !== 'その他' && selected !== '') {
      setDate(dayjs(selected));
    } else {
      setDate(null);
    }
  }, [watch('dateSelect')]);

  useEffect(() => {
    if (date) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [date]);
  return (
    <form
      onSubmit={handleSubmit(isValid, isInValid)}
      className="flex w-full flex-col gap-2"
    >
      <H2 className="text-danger text-center dela">新規投稿</H2>
      <Label>インライ日時</Label>
      <FormControl>
        <Select
          type="select"
          required
          color="primary"
          {...register('dateSelect', { required: '選択してください' })}
          className="w-[340px] m-auto"
          placeholder="選択してください"
          name="dateSelect"
        >
          {select.map((value) => {
            return (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {watch('dateSelect') === 'その他' && (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={ja as unknown as string}
          dateFormats={{ monthAndYear: 'YYYY年 MM月' }}
          localeText={{
            previousMonth: '前月を表示',
            nextMonth: '次月を表示',
            cancelButtonLabel: 'キャンセル',
            okButtonLabel: '選択',
          }}
        >
          <DatePicker
            className="w-[340px] m-auto"
            onChange={(newValue) => setDate(newValue as Dayjs)}
          />
        </LocalizationProvider>
      )}
      {elementCount.map((_, index) => {
        return (
          <div key={index} className="my-2">
            {elementCount.length === 1 ? (
              <div className="flex flex-row">
                <Label>URL</Label>
              </div>
            ) : (
              <div className="flex flex-row">
                <Label>Part{index + 1}</Label>
                <Button
                  onClick={() => {
                    const newElementCount = [...elementCount];
                    newElementCount.splice(index, 1);
                    setElementCount(newElementCount);
                  }}
                >
                  <Minus fill="#D83131" />
                </Button>
              </div>
            )}
            <div className="m-auto text-center">
              <TextField
                {...register(`Part${index + 1}`, {
                  required: 'URLを入力してください',
                })}
                typeof="url"
                placeholder="URLを入力してください"
                className="w-[340px]"
                type="url"
                name={`Part${index + 1}`}
                variant="outlined"
                required
              />
            </div>
          </div>
        );
      })}

      <OptionalButton
        variant="outlined"
        icon="Plus"
        color="success"
        className="w-[185px] mx-auto mt-[35px]"
        onClick={() => {
          setElementCount([...elementCount, '']);
        }}
      >
        Partを追加
      </OptionalButton>
      <Label>説明</Label>
      <div className="text-center mt-4">
        <TextField
          {...register('description',{
            required: '動画の説明，推しポイントなどを入力してください',
          })}
          multiline
          rows={3}
          typeof="url"
          placeholder="動画の説明，推しポイントなど"
          className="w-[340px]"
          type="text"
          name={`description`}
          variant="outlined"
          required
        />
      </div>
      <CustomButton
        className="mt-10 w-[336px] m-auto"
        type="submit"
        disabled={isDisabled}
      >
        投稿する
      </CustomButton>
      <CustomButton
        className="my-5 w-[336px] m-auto"
        color="primary"
        variant='text'
        onClick={() => {
          navigate(-1);
        }}
      >
        戻る
      </CustomButton>
      <AlertDialog
        open={isOpen}
        handleClose={closeModal}
        onClick={async () => {
          const id = randomID();
          const collectionId = 'liveclips';
          const documentId = dayjsToString(date as Dayjs);
          const subCollectionId = 'videos';
          const subDocId = id;

          console.log(url[0]);
          const meta = await createOGP(
            `OGP/${documentId}/${id}`,
            dayjsToEmbeddedText(date as Dayjs),
            url[0]
          );

          const data: LiveclipsDetails = {
            id,
            date: dayjsToDate(date as Dayjs),
            url,
            ogp: meta.ogp,
            thumbnail: meta.thumbnail,
            favorite: 0,
            tags: [],
            postDate: new Date(),
            uploader: {
              uid: authUser?.uid as string,
              displayName: authUser?.displayName as string,
              photoURL: authUser?.photoURL as string,
              screenName: authUser?.reloadUserInfo.screenName as string,
            },
            author: authUser?.reloadUserInfo.screenName,
            preview: 0,
            violation: 0,
            platform: getPlatformName(url[0]),
            description: description,
          };

          await setSubSpecificCollection<LiveclipsDetails>(
            collectionId,
            documentId,
            subCollectionId,
            subDocId,
            data
          );
          await setSubSpecificCollection<LiveclipsDetails>(
            'users',
            authUser?.reloadUserInfo.screenName,
            'liveclips',
            id,
            data
          );
          const upload_count = await getSubCollectionCount(
            collectionId,
            documentId,
            subCollectionId
          );
          await setSpecificCollection<Partial<Liveclips>>(
            collectionId,
            documentId,
            {
              thumbnail: meta.thumbnail,
              ogp: meta.ogp,
              upload_count,
              date: dayjsToDate(date as Dayjs),
            }
          );
          navigate('/liveclips/contents/' + documentId);
        }}
        title={'以下の内容で投稿しますか？'}
      >
        <div className="flex flex-col gap-2">
          <Label sm>インライ日時</Label>
          <div className="ml-7 text-gray70">{date && dayjsToString(date)}</div>
          <Label sm>動画パート数</Label>
          <div className="ml-7 text-gray70">{url && url.length}部</div>
          <Label sm>説明</Label>
          <div className="ml-7 text-gray70">
            {description !== '' ? description : '記載なし'}
          </div>
          <Label sm>投稿プラットフォーム</Label>
          <div className="ml-7 text-gray70">
            {url[0] && getPlatformName(url[0])}
          </div>
        </div>
      </AlertDialog>
    </form>
  );
};
