import React, { useEffect, useRef, useState } from 'react';
import { SmallCard } from '../../components/atoms/card/SmallCard';
import { BasicFab } from '../../components/atoms/button';
import { useNavigate } from 'react-router-dom';
import { Features } from '../../../types';
import { getAllDocument } from '../../../api/firestore';
import LoginModal from '../../components/molecules/modal/LoginModal';
import { useModal } from '../../../hooks/useModal';
import { useAuthContext } from '../../../provider/AuthProvider';
import AlertDialog from '../../components/molecules/modal/AlertDialog';
import { deleteFeatures } from '../../../api/firestore/delete';
import { MenuItem, Select } from '@mui/material';
import { convertToCategoryCountFormat } from '../../../utils/helper';

export const Feature = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Features[]>([]);
  const loginModal = useModal();
  const { authUser } = useAuthContext();
  const deleteModal = useModal();
  const selectId = useRef<string>('');
  const [category, setCategory] = useState<string[]>([]);
  const [select, setSelect] = useState<string>('すべて');

  useEffect(() => {
    getAllDocument<Features>('features').then((res) => {
      setData(res.data);
      setCategory(convertToCategoryCountFormat(res.data));
    });
  }, []);

  return (
    <div className="relative">
      <div className="text-center mt-3">
        <Select
          type="select"
          required
          color="primary"
          className="w-[340px]"
          placeholder="選択してください"
          defaultValue={'すべて'}
          onChange={(e) => {
            setSelect(e.target.value);
          }}
        >
          {['すべて', ...category].map((value) => {
            return (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <BasicFab
        className="fixed bottom-10 right-5"
        color="primary"
        icon="Post"
        onClick={() => {
          if (!authUser) {
            loginModal.openModal();
            return;
          }
          navigate('/feature/post');
        }}
      />
      {data
        .filter((item) =>
          (select !== 'すべて') ? item.category === select : item
        )
        .map((item, index) => {
          return (
            <SmallCard
              // isUploader={item.uploader.uid === authUser?.uid}
              isUploader={item.uploader.uid === authUser?.uid}
              category={item.category}
              thumbnail={item.thumbnail}
              onClick={() => {
                const win = window.open(item.url, '_blank');
                win?.focus();
              }}
              onDelete={() => {
                deleteModal.openModal();
                selectId.current = item.id;
              }}
              key={index}
            >
              {item.title}
            </SmallCard>
          );
        })}
        <div className='p-3'/>
      <LoginModal
        open={loginModal.isOpen}
        handleClose={() => {
          loginModal.closeModal();
        }}
      />
      <AlertDialog
        open={deleteModal.isOpen}
        handleClose={deleteModal.closeModal}
        onClick={async () => {
          await deleteFeatures(selectId.current, authUser?.reloadUserInfo.screenName ?? '');
          window.location.reload();
        }}
        color="error"
        title={'この投稿を削除しますか？'}
      >
        <div />
      </AlertDialog>
    </div>
  );
};
