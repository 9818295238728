import React from 'react';
interface Props {
  children?: React.ReactNode;
  className?: string;
  sm?: boolean;
}
export const H1 = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontSize: '60px',
      }}
    >
      {props.children}
    </div>
  );
};
export const H2 = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontSize: '45px',
      }}
    >
      {props.children}
    </div>
  );
};
export const H3 = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontSize: '30px',
      }}
    >
      {props.children}
    </div>
  );
};
export const H4 = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontSize: '20px',
      }}
    >
      {props.children}
    </div>
  );
};
export const H6 = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontSize: '15px',
      }}
    >
      {props.children}
    </div>
  );
};
export const Label = (props: Props) => {
  return (
    <div className={props.className}>
      {
        !props.sm?
    <div
      className={'text-danger noto-bold border-l-[8px] border-danger ml-3 my-2'}
      style={{
        fontSize: '24px',
      }}
    >
      <span className="ml-2">{props.children}</span>
    </div>:
      <div
      className={'text-danger noto-bold border-l-[8px] border-danger ml-3 my-2'}
      style={{
        fontSize: '18px',
      }}
    >
      <span className="ml-2">{props.children}</span>
    </div>
    }
    </div>
  );
};
