import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { MenuIcon } from '../../../../assets/icons/MenuIcon';
import { useNavigate } from 'react-router-dom';
import { DrawerLeft } from './DrawerLeft';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthContext } from '../../../../provider/AuthProvider';
import { Avatar } from '@mui/material';

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" color="error">
      <DrawerLeft isMenuOpen={isMenuOpen} setIsmenu={setIsMenuOpen} />
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => {
            setIsMenuOpen(true);
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          <div
            className="dela absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer no_copy"
            onClick={() => {
              navigate('/');
            }}
          >
            惟真伝心板
          </div>
        </Typography>
        {authUser && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt="user icon" src={authUser.photoURL ?? ''} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  const auth = getAuth();
                  signOut(auth)
                    .then(() => {
                      window.location.href = '/';
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                ログアウト
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
