import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LiveclipsDetails } from '../../../types';
import { getSubSpecificCollection } from '../../../api/firestore';
import {
  createOgpUrl,
  formatDate,
  formatDateHyphen,
  isMobile,
  tweetShareMessage,
} from '../../../utils/helper';
import { Timestamp } from 'firebase/firestore';
import { Icon } from '../../../assets/icons/Component';
import { CustomButton, OptionalButton } from '../../components/atoms/button';
import { Label } from '../../components/atoms/header';
import styled from '@emotion/styled';
import { useAuthContext } from '../../../provider/AuthProvider';
import AlertDialog from '../../components/molecules/modal/AlertDialog';
import { useModal } from '../../../hooks/useModal';
import { deleteLiveClips } from '../../../api/firestore/delete';
import { Footer } from '../../components/atoms/footer';
import LoginModal from '../../components/molecules/modal/LoginModal';
import { ReportModal } from '../../components/organisms/appbar/modal/ReportModal';

export const LiveClipsDetails = () => {
  const Chart = Icon['Chart'];
  const [data, setData] = useState<LiveclipsDetails | null>();
  const params = useParams();
  const navigate = useNavigate();
  const currentURL = window.location.href;
  const parsedURL = useRef(new URL(currentURL));
  const path = parsedURL.current.pathname;
  const auth = useAuthContext();
  const deleteModal = useModal();
  const loginModal = useModal();
  const reportModal = useModal();

  useEffect(() => {
    getSubSpecificCollection<LiveclipsDetails>(
      'liveclips',
      params.date as string,
      'videos',
      params.id as string
    ).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <div>
      {data?.id && (
        <div className="relative">
          <div className="text-primary text-center my-3 text-[23px]">
            動画ID: {data?.id}
          </div>
          <img src={data?.thumbnail} alt="ogp" className="w-[338px] m-auto" />
          <div className="flex flex-raw space-x-36 justify-center text-[17px]">
            <div className="text-primary">
              {formatDate((data?.date as Timestamp).toDate())}
            </div>
            <button className="flex flex-raw">
              <div>
                <Chart fill="#D83131" />
              </div>
              <div
                className="text-danger noto-reg"
                onClick={() => {
                  if (!auth.authUser) {
                    loginModal.openModal();
                    return;
                  }
                  reportModal.openModal();
                }}
              >
                報告する
              </div>
            </button>
          </div>
          <div className="text-center mt-5">
            <OptionalButton
              size="medium"
              icon="Twitter"
              color="info"
              className="w-[338px]"
              href={tweetShareMessage(
                `${formatDate(
                  (data.date as Timestamp).toDate()
                )}の惟真くんのインスタライ切り抜きをチェックしよう！`,
                createOgpUrl(
                  path,
                  formatDateHyphen((data.date as Timestamp).toDate()),
                  data.id
                )
              )}
            >
              Twitter(X)で共有する
            </OptionalButton>
          </div>
          {isMobile() && (
            <div className="text-center mb-5 mt-3">
              <OptionalButton
                size="medium"
                icon="LinkIcon"
                color="warning"
                className="w-[338px]"
                onClick={async () => {
                  if (typeof window === 'undefined') {
                    return;
                  }
                  navigator.clipboard.writeText(
                    createOgpUrl(
                      path,
                      formatDateHyphen((data.date as Timestamp).toDate()),
                      data.id
                    )
                  );
                  alert('リンクをコピーしました');
                }}
              >
                リンクコピー
              </OptionalButton>
            </div>
          )}

          {data?.url.map((item, index, self) => {
            return (
              <div key={index} className="my-10">
                <Label className="text-danger ml-4">
                  {self.length === 1 ? 'コンテンツ' : `Part${index + 1}`}
                </Label>
                <div className="text-center my-4">
                  <CustomButton
                    variant="contained"
                    className="w-[230px]"
                    onClick={() => {
                      window.location.href = item;
                    }}
                  >
                    視聴する
                  </CustomButton>
                </div>
              </div>
            );
          })}
          {data?.description && (
            <DescriptionWrapper>{data.description}</DescriptionWrapper>
          )}
          <div className="text-center">
            {data?.uploader.uid === auth.authUser?.uid && (
              <CustomButton
                className="my-2 w-[336px] m-auto"
                color="error"
                variant="outlined"
                onClick={deleteModal.openModal}
              >
                投稿を削除
              </CustomButton>
            )}
            <CustomButton
              className="my-5 w-[336px] m-auto"
              color="primary"
              onClick={() => {
                navigate('/');
              }}
              variant="text"
            >
              HOMEに戻る
            </CustomButton>
          </div>
        </div>
      )}

      <AlertDialog
        open={deleteModal.isOpen}
        handleClose={deleteModal.closeModal}
        onClick={async () => {
          deleteLiveClips(
            formatDateHyphen((data?.date as Timestamp).toDate()),
            data?.id as string,
            auth.authUser?.reloadUserInfo.screenName
          );
          navigate('/');
        }}
        color="error"
        title={'この投稿を削除しますか？'}
      >
        <div />
      </AlertDialog>
      <LoginModal
        open={loginModal.isOpen}
        text="報告する"
        handleClose={() => {
          loginModal.closeModal();
        }}
      />
      <ReportModal
        id={data?.id ?? ''}
        uploader={data?.uploader.screenName ?? ''}
        reportUser={auth.authUser?.reloadUserInfo.screenName}
        isOpen={reportModal.isOpen}
        closeModal={reportModal.closeModal}
        openModal={reportModal.openModal}
      />
      <Footer />
    </div>
  );
};

const DescriptionWrapper = styled.div`
  width: 338px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #dfdfdf;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;
