import React from 'react'

export const Footer = () => {
    return (
        <div className="w-full h-[100px] bg-gray-100 flex justify-center items-center">
            <div className="text-gray-500 text-sm no_copy">
                © 2023 惟真伝心板
            </div>
        </div>
    )
}
