import React from 'react';
interface IconsProps {
  size?: string;
}
export const MenuIcon = ({ size = '40' }: IconsProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0418 15.2982C38.1773 21.4115 27.8386 39.7514 23.7032 39.7514C19.5677 39.7514 19.5677 37.7136 17.5 37.7136C15.4323 37.7136 15.4323 39.7514 11.2968 39.7514C7.16136 39.7514 -3.17728 21.4115 0.958176 15.2982C5.09363 9.18495 11.2968 9.18495 15.4323 11.2227V5.10943C3.81164 11.3654 1.18563 2.62336 1.18563 2.62336C1.18563 2.62336 6.68578 -4.6922 15.4323 5.10943V1.03391H19.5677V11.2227C23.7032 9.18495 29.9064 9.18495 34.0418 15.2982Z"
        fill="white"
      />
      <rect x="6" y="16" width="22" height="3" fill="#D83131" />
      <rect x="6" y="22" width="22" height="3" fill="#D83131" />
      <rect x="6" y="28" width="22" height="3" fill="#D83131" />
    </svg>
  );
};
