import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from '../firebase';

export const getAllDocument = async <T extends DocumentData>(
  collectionId: string
) => {
  const data: T[] = [];
  const id: string[] = [];
  const queryParams = query(
    collection(db, collectionId),
    orderBy('date', 'desc')
  );
  const querySnapshot = await getDocs(queryParams);
  querySnapshot.forEach((doc) => {
    data.push(doc.data() as T);
    id.push(doc.id);
  });
  return { data, id };
};

export const getSpecificCollection = async <T extends DocumentData>(
  collectionId: string,
  docId: string
) => {
  const docRef = doc(db, collectionId, docId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    console.log('No such document!');
    return null;
  }
  return docSnap.data() as T;
};

export const getSubAllCollection = async <T extends DocumentData>(
  collectionId: string,
  docId: string,
  subCollectionId: string,
  key: string,
) => {
  const data: T[] = [];
  const queryParams = query(
    collection(db, collectionId, docId, subCollectionId),
    orderBy(key, 'desc')
  );
  const querySnapshot = await getDocs(queryParams);
  querySnapshot.forEach((doc) => {
    data.push(doc.data() as T);
  });
  return data;
};

export const getSubSpecificCollection = async <T extends DocumentData>(
  collectionId: string,
  docId: string,
  subCollectionId: string,
  subDocId: string
) => {
  const docRef = doc(db, collectionId, docId, subCollectionId, subDocId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    console.log('No such document!');
    return null;
  }
  return docSnap.data() as T;
};
