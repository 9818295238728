import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { shortenString } from '../../../../utils/helper';
import { Icon } from '../../../../assets/icons/Component';
import { Features } from '../../../../types';

interface Props extends Partial<Features> {
  children?: ReactNode;
  onClick?: () => void;
  onDelete?: () => void;
  isUploader?: boolean;
}

export const SmallCard = (props: Props) => {
  const Trash = Icon['Trash'];

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onDelete && props.onDelete();
  };

  return (
    <Wrapper onClick={props.onClick}>
      <div className="absolute top-2 left-5 transform text-[13px] text-primary noto-bold">
        {props.category}
      </div>
      <div className="absolute top-1/2 left-5 transform -translate-y-1/2 text-[24px] text-gray70 noto-reg w-[60%]">
        {shortenString(props.children as string, 15)}
      </div>
      {props.isUploader && (
        <button
          className="absolute bottom-2 left-2 transform text-[13px noto-bold pointer-events-auto]"
          onClick={handleEditClick}
        >
          <div>
            <Trash fill="#D83131" />
          </div>
        </button>
      )}
      <ImageBackground
        className="absolute top-1/2 right-5 transform -translate-y-1/2"
        image={
          props.thumbnail ||
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png'
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90%;
  height: 130px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  margin: 15px auto;
  padding: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  pointer-events: auto; // ボタンのクリックを有効化
`;

const ImageBackground = styled.div<{ image: string }>`
  width: 100px;
  height: 100px;
  background-image: url(${(props) => props.image});
  border-radius: 50%;
  background-size: cover;
  background-position: 54% 26%;
`;
