import React, { useState } from 'react';
import AlertDialog from '../../../molecules/modal/AlertDialog';
import axios from 'axios';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface Props {
  id: string;
  uploader: string;
  reportUser: string;
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

const reportVariants = [
  '不適切な内容',
  '著作権侵害',
  '無断転載',
  '誤った情報',
  'その他',
];
export const ReportModal = (props: Props) => {
  const [reportContent, setReportContent] = useState(reportVariants[0]);
  return (
    <div>
      <AlertDialog
        open={props.isOpen}
        handleClose={props.closeModal}
        onClick={async () => {
          await axios.get(
            `https://script.google.com/macros/s/AKfycbz09TycO7hyj82XiSd6QzCs_rFxLhhmK5b8HeSZN4L6oKqAnvnYfzJLgMqOYcFUZn1T/exec?${
              'id=' +
              props.id +
              '&uploader=' +
              props.uploader +
              '&reportUser=' +
              props.reportUser +
              '&reportContent=' +
              reportContent
            }`
          );
        }}
        color="error"
        title={'この投稿を報告しますか？'}
      >
        <FormControl className="ml-4">
          <FormLabel id="demo-radio-buttons-group-label">報告内容</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {reportVariants.map((item, index) => {
              return (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={reportContent}
                  onChange={(e) => {
                    setReportContent(e.target.value);
                  }}
                  key={index}
                >
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                </RadioGroup>
              );
            })}
          </RadioGroup>
        </FormControl>
      </AlertDialog>
    </div>
  );
};
