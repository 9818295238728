import React from 'react';
import { Instagram, Twitter, TikTok } from '../../assets/icons';
import { CustomButton } from '../components/atoms/button';
import { H3, H6 } from '../components/atoms/header';
import { IconLabelSize } from '../../config/size';

export const Profile = () => {
  return (
    <div>
      <H3 className="dela text-danger m-3 text-center">Profile</H3>
      <img
        className="w-[250px] m-auto"
        src="https://supafuru.jp/cms/wp-content/uploads/TSF_solo_mainAP_summer_OI1.jpg"
        alt="oda_ishin"
      />
      <H3 className="noto-bold text-black mt-3 ml-[50px]">小田惟真</H3>
      <H6 className="noto-bold text-black ml-[50px]">ODA ISHIN</H6>
      <H6 className="noto-reg text-gray70 m-3 ml-[50px]">
        誕生日: 2007/01/10
        <br />
        出身地: 埼玉県
        <br />
        身長: 173cm
        <br />
        血液型: 不明
        <br />
        特技: 変顔・カメラワーク(動画撮影)
        <br />
        趣味: アイドル鑑賞・お菓子作り
      </H6>

      <div className="flex flex-row gap-2 ml-[50px]">
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = 'https://www.instagram.com/ishin_supafuru/';
          }}
        >
          <Instagram fill="#000" size={IconLabelSize.large.size} />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = 'https://twitter.com/ishin_supafuru';
          }}
        >
          <Twitter fill="#000" size={IconLabelSize.large.size} />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = 'https://www.tiktok.com/@ishin._.0110';
          }}
        >
          <TikTok fill="#000" size={IconLabelSize.large.size} />
        </div>
      </div>
      <div className="text-center m-10">
        <CustomButton
          onClick={() => {
            window.location.href = 'https://supafuru.jp/profile/oda_ishin/';
          }}
        >
          公式サイトへ
        </CustomButton>
      </div>
    </div>
  );
};
