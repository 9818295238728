import { Size } from '../types/union.type';

export const IconLabelSize = {
  small: {
    size: '24px',
    fontSize: '20px',
  },
  medium: {
    size: '37px',
    fontSize: '24px',
  },
  large: {
    size: '48px',
    fontSize: '30px',
  },
  xlarge: {
    size: '67px',
    fontSize: '35px',
  },
} satisfies Record<
  Size,
  {
    size: string;
    fontSize: string;
  }
>;
