import React from 'react';
import { Button, ThemeProvider } from '@mui/material';
import { theme } from '../../../styles/theme';
import { Icons } from '../../../../types/union.type';
import { IconLabelSize } from '../../../../config/size';
import { Icon } from '../../../../assets/icons/Component';

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  icon?: Icons;
  padding?: string;
  onClick?: () => void;
}

export const PanelButton: React.FC<Props> = ({
  children,
  icon = 'Contact',
  disabled = false,
  onClick,
}) => {
  const Component = Icon[icon];
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        disabled={disabled}
        style={{
          padding: 0,
          opacity: 0.8,
          borderRadius: 0,
          width: '108px',
          height: '108px',
          backgroundColor: disabled ? '#707070' : '#D83131',
        }}
        variant="contained"
      >
        <div className="flex flex-col items-center justify-center mt-2">
          <Component fill="#fff" size={IconLabelSize.large.size} />
          <div
            style={{
              fontSize: IconLabelSize.small.fontSize,
              color: '#fff',
            }}
          >
            {children}
          </div>
        </div>
      </Button>
    </ThemeProvider>
  );
};
