import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from '../views/pages/Home';
import { Profile } from '../views/pages/Profile';
import ResponsiveAppBar from '../views/components/organisms/appbar';
import '../views/styles/App.css';
import { Feature } from '../views/pages/feature/Feature';
import { FeaturePost } from '../views/pages/feature/FeaturePost';
import { LiveClipsPost } from '../views/pages/liveclips/LiveClipsPost';
import { LiveClips } from '../views/pages/liveclips/LiveClip';
import { LiveClipsContents } from '../views/pages/liveclips/LiveClipsContents';
import { LiveClipsDetails } from '../views/pages/liveclips/LiveClipsDetails';
import { ScrollToTop } from '../hooks/ScrollToTop';
import { TwitterAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { auth } from '../api/firebase';
import { useAuthContext } from '../provider/AuthProvider';
import { Terms } from '../views/pages/Terms';
import { PrivacyPolicy } from '../views/pages/PrivacyPolicy';
export const provider = new TwitterAuthProvider();
export const Router = () => {
  const { isLogin, setIsLogin, setAuthUser } = useAuthContext();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLogin(true);
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
  }, []);

  return (
    <div className="sm:w-full md:w-[500px] lg:w-[500px] m-auto bg-white h-full">
      <div className="h-[1000px] w-full md:w-[500px] lg:w-[500px] m-auto bg-white absolute -z-10" />
      {isLogin && (
        <BrowserRouter>
          <ScrollToTop />
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/feature" element={<Feature />} />
            <Route path="/feature/post" element={<FeaturePost />} />

            <Route path="/liveclips" element={<LiveClips />} />
            <Route
              path="/liveclips/contents/:date"
              element={<LiveClipsContents />}
            />
            <Route
              path="/liveclips/contents/:date/:id"
              element={<LiveClipsDetails />}
            />
            <Route path="/liveclips/post" element={<LiveClipsPost />} />

            <Route path="/fancam" element={<LiveClips />} />
            <Route
              path="/fancam/contents/:date"
              element={<LiveClipsContents />}
            />
            <Route
              path="/fancam/contents/:date/:id"
              element={<LiveClipsDetails />}
            />
            <Route path="/fancam/post" element={<LiveClipsPost />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<PrivacyPolicy />} />
            {/* Not Found */}
            <Route path="*" element={<div>404 NotFound</div>} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default Router;
