import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { H2 } from '../../atoms/header';
import { OptionalButton } from '../../atoms/button';
import { TwitterAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { provider } from '../../../../router';
import { useAuthContext } from '../../../../provider/AuthProvider';
import { setSpecificCollection } from '../../../../api/firestore';

interface Props {
  open: boolean;
  text?: string;
  handleClose: () => void;
}
export default function LoginModal(props: Props) {
  const { setAuthUser } = useAuthContext();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '13px',
          },
        }}
      >
        <H2 className="dela text-danger text-center m-3 mt-5">惟真伝心板</H2>
        <div className="mx-3 text-[22px] text-gray70 text-center">
          {props.text ? props.text : '投稿'}にはログインが必要です現在、X
          (旧Twitter)のみログインに対応しています
        </div>

        <div className="text-center text-gray70 text-[10px] mt-5 w-[250px] mx-auto">
          ログインすることで、惟真伝心板の
          <a href="/terms" className="text-primary">
            利用規約
          </a>
          ・
          <a href="/policy" className="text-primary">
            プライバシーポリシー
          </a>
          に同意したものとします。
        </div>
        <div className="text-center mt-5 mb-7">
          <OptionalButton
            size="medium"
            icon="Twitter"
            color="info"
            className="w-[277px]"
            onClick={() => {
              props.handleClose();
              const auth = getAuth();
              signInWithPopup(auth, provider)
                .then((result) => {
                  setAuthUser(result.user);
                  setSpecificCollection(
                    'users',
                    result.user.reloadUserInfo.screenName,
                    {}
                  );
                  props.handleClose();
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  const email = error.customData.email;
                  const credential =
                    TwitterAuthProvider.credentialFromError(error);
                  console.error(errorCode, errorMessage, email, credential);
                  setAuthUser(null);
                });
            }}
          >
            Twitterでログイン
          </OptionalButton>
        </div>
      </Dialog>
    </div>
  );
}
