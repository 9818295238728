import React from 'react';
import { Footer } from '../components/atoms/footer';

export const PrivacyPolicy = () => {
  return (
    <div className="japanese_L mx-2 mt-2">
      <div className="mx-auto max-w-3xl rounded-lg bg-white p-[2%] [&_h2]:my-[0.83em] [&_h2]:text-[1.5em] [&_h2]:font-bold [&_li]:list-decimal [&_ul]:ml-4 [&_ul_ul>li]:list-[lower-latin]">
        <h1 className="japanese_L my-5 text-center text-[2em] font-bold text-runticketGrayText">
        プライバシーポリシー
        </h1>
        <div className="m-auto my-5 w-[90%]">
          <h2>1. 個人情報の収集</h2>
          <p>
            当サイトは、Twitterログイン機能を使用しており、ログインの際に以下の情報を収集します
          </p>
          <ul>
            <li>TwitterユーザーID</li>
            <li>プロフィール画像</li>
            <li>表示名</li>
          </ul>

          <h2>2. 個人情報の利用目的</h2>
          <p>収集した情報は、以下の目的で利用します</p>
          <ul>
            <li>当サイト内でのユーザー識別</li>
            <li>ユーザーサポートや問い合わせ対応</li>
            <li>不正アクセスやスパム対策</li>
          </ul>

          <h2>3. 個人情報の第三者への提供</h2>
          <p>
            当サイトは、以下の場合を除き、収集した個人情報を第三者に提供することはありません。
          </p>
          <ul>
            <li>法的な義務に基づき開示が必要な場合</li>
            <li>
              当サイトの権利、財産、サービスの保護・運営のために必要な場合
            </li>
          </ul>

          <h2>4. クッキーの利用</h2>
          <p>
            当サイトは、ユーザーエクスペリエンスの向上やサイトの最適化のためにクッキーを利用することがあります。ユーザーはブラウザの設定でクッキーの利用を拒否することができますが、すべての機能が正常に動作しない場合があります。
          </p>

          <h2>5. プライバシーポリシーの変更</h2>
          <p>
            当サイトは、プライバシーポリシーを予告なく変更することがあります。変更後のプライバシーポリシーは、当サイト上での公開をもって、効力を発生するものとします。
          </p>

          <h2>6. お問い合わせ</h2>
          <p>
            当サイトのプライバシーポリシーに関するお問い合わせは、お問い合わせフォームよりご連絡ください。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
